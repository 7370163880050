import { useReducer, useEffect, useState } from 'react'
import { toast } from 'react-toastify'

// firebase
import { projectFirestore, timestamp } from '../firebase/config'

// alerts
import { errorMsg, successMsg } from '../alerts'

let initialState = {
  document: null,
  isPending: false,
  error: null,
  success: null
}

const firestoreReducer = (state, action) => {
  switch (action.type) {
    case 'IS_PENDING':
      return { isPending: true, document: null, success: false, error: null }
    case 'ADDED_DOCUMENT':
      return {
        isPending: false,
        document: action.payload,
        success: true,
        error: null
      }
    case 'REMOVED_DOCUMENT':
      return {
        isPending: false,
        document: null,
        success: true,
        error: null
      }
    case 'ERROR':
      return {
        isPending: false,
        document: null,
        success: false,
        error: action.payload
      }

    case 'RESET':
      return initialState
    default:
      return state
  }
}

export const useFirestore = (collection) => {
  const [response, dispatch] = useReducer(firestoreReducer, initialState)
  const [isCancelled, setIsCancelled] = useState(false)

  // collection ref
  const ref = projectFirestore.collection(collection)

  // only dispatch if not cancelled
  const dispatchIfNoCancelled = (action) => {
    if (!isCancelled) {
      dispatch(action)
    }
  }

  // add a document
  const addDocument = async (doc) => {
    dispatch({ type: 'IS_PENDING' })

    try {
      const createdAt = timestamp.fromDate(new Date())
      const addedDocument = await ref.add({
        ...doc,
        createdAt,
        nameLower: doc.name.toLowerCase()
      })
      dispatchIfNoCancelled({ type: 'ADDED_DOCUMENT', payload: addedDocument })

      // alert
      toast.dismiss()
      toast.success(successMsg.transAdded.replace('Firebase: ', ''))
    } catch (err) {
      dispatchIfNoCancelled({ type: 'ERROR', payload: err.message })

      // alert
      toast.dismiss()
      toast.error(errorMsg.noTransRemoved.replace('Firebase: ', ''))
    }
  }

  // remove a document
  const removeDocument = async (id) => {
    dispatch({ type: 'IS_PENDING' })

    try {
      await ref.doc(id).delete()
      dispatchIfNoCancelled({
        type: 'REMOVED_DOCUMENT'
      })

      // alert
      toast.dismiss()
      toast.success(successMsg.transRemoved.replace('Firebase: ', ''))
    } catch (err) {
      dispatchIfNoCancelled({ type: 'ERROR', payload: err.message })

      // alert
      toast.dismiss()
      toast.error(errorMsg.noTransRemoved.replace('Firebase: ', ''))
    }
  }

  useEffect(() => {
    return () => setIsCancelled(true)
  }, [])

  return { addDocument, removeDocument, response, dispatch }
}
