// scroll to top
function scrollToTop() {
  window.scroll({ top: 0, left: 0, behavior: 'smooth' })
}

// toggle password
function togglePassword(passwordField, eyeIcon) {
  if (passwordField.type === 'password') {
    passwordField.type = 'text'
    eyeIcon.classList.remove('bi-eye')
    eyeIcon.classList.add('bi-eye-slash')
  } else {
    passwordField.type = 'password'
    eyeIcon.classList.remove('bi-eye-slash')
    eyeIcon.classList.add('bi-eye')
  }
}

// paging
const paging = (c, m) => {
  let current = c,
    last = m,
    delta = 1, // 2
    left = current - delta,
    right = current + delta + 1,
    range = [],
    rangeWithDots = [],
    l

  for (let i = 1; i <= last; i++) {
    if (i === 1 || i === last || (i >= left && i < right)) {
      range.push(i)
    }
  }

  for (let i of range) {
    if (l) {
      if (i - l === 2) {
        rangeWithDots.push(l + 1)
      } else if (i - l !== 1) {
        rangeWithDots.push('...')
      }
    }
    rangeWithDots.push(i)
    l = i
  }

  return rangeWithDots
}

// currency formatter
const currencyFormatter = (value, options) => {
  const defaultOptions = {
    significantDigits: 2,
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbol: '$'
  }

  if (typeof value !== 'number') value = 0.0
  options = { ...defaultOptions, ...options }
  value = value.toFixed(options.significantDigits)

  const [currency, decimal] = value.split('.')
  return `${options.symbol}${currency.replace(
    /\B(?=(\d{3})+(?!\d))/g,
    options.thousandsSeparator
  )}${options.decimalSeparator}${decimal}`
}

// check empty object
function isEmptyObj(obj) {
  return Object.keys(obj).length === 0
}

// get query string
const getQueryString = (paramsObj, pathname) => {
  let params = new URLSearchParams()

  for (const [key, value] of Object.entries(paramsObj)) {
    params.set(key, value)
  }

  return params.toString() ? `${pathname}?${params.toString()}` : `${pathname}`
}

// update query string
const updateQueryString = (paramsObj, newParamsObj, pathname) => {
  const params = new URLSearchParams(paramsObj)

  for (const [key, value] of Object.entries(newParamsObj)) {
    params.set(key, value)

    if (value === '' || value === undefined) params.delete(key)
    if (key === 'page' && value === 1) params.delete(key)
    if (key === 'sort' && value === '*') params.delete(key)
  }

  return params.toString() ? `${pathname}?${params.toString()}` : `${pathname}`
}

// base 64
const toBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}

export {
  scrollToTop,
  togglePassword,
  paging,
  currencyFormatter,
  isEmptyObj,
  getQueryString,
  updateQueryString,
  toBase64
}
