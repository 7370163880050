import React from 'react'

const Disclaimer = () => {
  return (
    <div className="disclaimer">
      <div className="container">
        <div className="d-md-flex justify-content-md-between align-items-md-center">
          <div className="fw-light">Youcef Developer &copy; 2022</div>
          <div className="fw-light mt-4 mt-md-0">
            Technologies:
            <span className="badge fw-light ms-2">Javascript</span>
            <span className="badge fw-light ms-1">React</span>
            <span className="badge fw-light ms-1">Context API</span>
            <span className="badge fw-light ms-1">Firebase</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Disclaimer
