import React, { useEffect, useState, useRef, useCallback } from 'react'

// hooks
import { useFirestore } from '../hooks/useFirestore'

const TransactionForm = ({ uid }) => {
  const initialValues = {
    name: '',
    amount: ''
  }
  const [formValues, setFormValues] = useState(initialValues)
  const [formErrors, setFormErrors] = useState({})
  const [isSubmit, setIsSubmit] = useState(false)

  const { addDocument, response, dispatch } = useFirestore('transactions')

  // refs and callbacks
  const _dispatchRef = useRef(dispatch).current
  const _initialValuesRef = useRef(initialValues).current
  const _addDocumentRef = useRef(addDocument).current
  const _addDocumentCb = useCallback(() => {
    setIsSubmit(false)

    _addDocumentRef({
      uid,
      name: formValues.name.trim(),
      amount: formValues.amount
    })
  }, [_addDocumentRef, formValues.name, formValues.amount, uid])

  // handle change form fields
  const handleChange = (e) => {
    let { name, value } = e.target

    if (name === 'amount' && value && !value.match(/^\d{1,}(\.\d{0,2})?$/))
      return

    setFormValues({ ...formValues, [name]: value })
  }

  // validate form
  const validate = (values) => {
    const errors = {}

    if (!values.name.trim()) {
      errors.name = 'Transaction name is required'
    }

    if (!values.amount) {
      errors.amount = 'Amount is required'
    }

    return errors
  }

  // handle form fields changes
  const handleSubmit = (e) => {
    e.preventDefault()

    setFormErrors(validate(formValues))
    setIsSubmit(true)
  }

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      // store data to firestore
      _addDocumentCb()
    }

    // reset form once the data successfully added
    if (response.success) {
      setFormValues(_initialValuesRef)
      _dispatchRef({ type: 'RESET' })
    }
  }, [
    formErrors,
    isSubmit,
    _initialValuesRef,
    _addDocumentCb,
    response.success,
    _dispatchRef
  ])

  return (
    <>
      {/* formValues: <pre>{JSON.stringify(formValues, undefined, 2)}</pre>
      formErrors: <pre>{JSON.stringify(formErrors, undefined, 2)}</pre>
      isSubmit: <pre>{JSON.stringify(isSubmit, undefined, 2)}</pre> */}
      <form onSubmit={handleSubmit} noValidate>
        <div className="mb-3">
          <label htmlFor="name" className="form-label">
            Transaction name
          </label>
          <input
            type="text"
            className={`form-control form-control-lg ${
              formErrors.name !== undefined && 'is-invalid'
            }`}
            id="name"
            name="name"
            value={formValues.name}
            onChange={handleChange}
          />
          {formErrors.name && (
            <div className="invalid-feedback fw-light">{formErrors.name}</div>
          )}
        </div>
        <div className="mb-3">
          <label htmlFor="amount" className="form-label">
            Amount
          </label>
          <div className="form-currency">
            <input
              type="text"
              className={`form-control form-control-lg ${
                formErrors.amount !== undefined && 'is-invalid'
              }`}
              id="amount"
              name="amount"
              value={formValues.amount}
              onChange={handleChange}
            />
            {formErrors.amount && (
              <div className="invalid-feedback fw-light">
                {formErrors.amount}
              </div>
            )}
          </div>
        </div>
        {false ? (
          <button
            type="submit"
            className="btn btn-primary btn-lg mt-2 mb-3 disabled"
          >
            <span className="spinner-border spinner-form-button"></span>
            Add Transaction
          </button>
        ) : (
          <button
            type="submit"
            className="btn btn-outline-primary btn-lg mt-2 mb-3"
          >
            Add Transaction
          </button>
        )}
      </form>
    </>
  )
}

export default TransactionForm
