import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import 'firebase/compat/auth'

const firebaseConfig = {
  apiKey: 'AIzaSyDFnZ-bykEG9UhtU_02xYmZTV0My5kHWnY',
  authDomain: 'youdev-mymoney-app.firebaseapp.com',
  projectId: 'youdev-mymoney-app',
  storageBucket: 'youdev-mymoney-app.appspot.com',
  messagingSenderId: '397797408245',
  appId: '1:397797408245:web:e8724266ab25e9579434bc'
}

// init firebase
firebase.initializeApp(firebaseConfig)

// init services
const projectFirestore = firebase.firestore()
const projectAuth = firebase.auth()

// timestamp
const timestamp = firebase.firestore.Timestamp

export { projectFirestore, projectAuth, timestamp }
