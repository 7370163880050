import React, { useEffect } from 'react'
import { Link, NavLink } from 'react-router-dom'

import { defaultProfileMaleSketch } from '../images'

// hooks
import { useLogout } from '../hooks/useLogout'
import { useAuthContext } from '../hooks/useAuthContext'

const Navbar = () => {
  const { logout } = useLogout()
  const { user } = useAuthContext()

  const handleLogout = (e) => {
    e.preventDefault()
    logout()
  }

  const closeMenu = (toggle, content) => {
    toggle.classList.add('collapsed')
    toggle.setAttribute('aria-expanded', false)
    content.classList.remove('show')
  }

  useEffect(() => {
    const navItems = document.querySelectorAll('.nav-link-listener')
    const navbarContent = document.querySelector('#navbarContent')
    const navbarToggle = document.querySelector('.navbar-toggler')

    navItems.forEach(() => {
      document.addEventListener('click', (e) => {
        if (e.target.classList.contains('nav-link-listener')) {
          closeMenu(navbarToggle, navbarContent)
        }
      })
    })
  }, [])

  return (
    <nav className="navbar navbar-expand-md navbar-dark bg-primary mb-5">
      <div className="container">
        <Link className="navbar-brand" to="/">
          My Money
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarContent"
          aria-controls="navbarContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <div className="hamburger-menu">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </button>
        <div className="collapse navbar-collapse" id="navbarContent">
          <ul className="navbar-nav ms-auto mt-3 mt-md-0">
            {!user ? (
              <>
                <li className="nav-item">
                  <NavLink className="nav-link nav-link-listener" to="/login">
                    Login
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className="nav-link nav-link-listener"
                    to="/register"
                  >
                    Register
                  </NavLink>
                </li>
              </>
            ) : (
              <>
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="!#"
                    id="navbarDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      className="profile-login-icon"
                      src={defaultProfileMaleSketch}
                      alt="..."
                    />
                    Hi, {user.displayName ? user.displayName : 'Guest'}
                  </a>
                  <ul
                    className="dropdown-menu dropdown-menu-end"
                    aria-labelledby="navbarDropdown"
                  >
                    {/* <li>
                      <NavLink
                        className="dropdown-item nav-link-listener"
                        to="/profile"
                      >
                        Profile
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        className="dropdown-item nav-link-listener"
                        to="/settings"
                      >
                        Settings
                      </NavLink>
                    </li> */}
                    <li>
                      <NavLink
                        className="dropdown-item nav-link-listener"
                        to="/"
                      >
                        Home
                      </NavLink>
                    </li>
                    <li>
                      <hr className="dropdown-divider" />
                    </li>
                    <li>
                      <NavLink
                        className="dropdown-item nav-link-listener"
                        to="/logout"
                        onClick={handleLogout}
                      >
                        Logout
                      </NavLink>
                    </li>
                  </ul>
                </li>
              </>
            )}
          </ul>
        </div>
      </div>
    </nav>
  )
}

export default Navbar
