import React from 'react'
import { Link } from 'react-router-dom'

// components
import TransactionForm from '../component/TransactionForm'
import SkeletonItem from '../component/skeletons/SkeletonItem'

// hooks
import { useAuthContext } from '../hooks/useAuthContext'
import { useCollection } from '../hooks/useCollection'
import TransactionList from '../component/TransactionList'

// utils
import { currencyFormatter } from '../utils'

const Home = () => {
  const { user } = useAuthContext()
  const { documents, isPending, error, subTotal } = useCollection(
    'transactions',
    ['uid', '==', user.uid],
    ['createdAt', 'desc']
  )

  return (
    <>
      <div className="row">
        <div className="col-lg-4 order-lg-last mb-5">
          <div className="d-flex justify-content-between align-items-center">
            <h2 className="mb-0 d-block d-lg-none">Transactions</h2>
            <button
              type="button"
              className="btn btn-md btn-primary d-lg-none fw-light"
              data-bs-toggle="collapse"
              data-bs-target="#transForm"
              aria-expanded="false"
              aria-controls="transForm"
            >
              + Add
            </button>
          </div>
          <div className="navbar-expand-lg">
            <div className="navbar-collapse collapse" id="transForm">
              <div className="card w-100 mt-4 mt-lg-0">
                <div className="card-header card-header-lg d-none d-lg-block">
                  Add a Transaction
                </div>
                <div className="card-body mt-3 mt-lg-0">
                  <TransactionForm uid={user.uid} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-8 order-lg-first">
          <div>
            <h2 className="mb-4 d-none d-lg-block">Transactions</h2>

            {isPending && (
              <>
                <SkeletonItem />
                <SkeletonItem />
                <SkeletonItem />
                <SkeletonItem />
                <SkeletonItem />
              </>
            )}
            {error && <p className="lead fw-light mt-5 text-center">{error}</p>}
            {documents && !isPending && (
              <>
                {documents.length > 0 && (
                  <div className="d-flex justify-content-between align-items-center fw-light mt-0 mt-lg-5 mb-3">
                    <h6 className="ms-1 me-3 fw-light">
                      Subtotal ({documents.length}{' '}
                      {documents.length === 1 ? 'transaction' : 'transactions'})
                    </h6>
                    <h4 className="me-1 text-dark fw-medium">
                      {currencyFormatter(subTotal)}
                    </h4>
                  </div>
                )}
                <TransactionList transactions={documents} />
              </>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default Home
