export const successMsg = {
  registered: 'You have been successfully registered.',
  loggedOut: 'You have been successfully logged out.',
  loggedIn: 'You have been successfully logged in.',
  transAdded: 'Transaction has been successfully added.',
  transRemoved: 'Transaction has been successfully removed.'
}

export const errorMsg = {
  registered: 'Could not complete registration.',
  loggedIn: 'Could not login.',
  incorrectCred: 'Incorrect email address or password.',
  noFetchData: 'Could not fetch the data.',
  noTransAdded: 'Could not add transaction.',
  noTransRemoved: 'Could not remove transaction.'
}
