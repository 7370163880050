import React, { useState, useEffect, useCallback, useRef } from 'react'
import { Link } from 'react-router-dom'

// utils
import { togglePassword } from '../utils'

// hooks
import { useRegister } from '../hooks/useRegister'

const Register = () => {
  const initialValues = {
    name: '',
    email: '',
    password: '',
    confirmPassword: ''
  }
  const [formValues, setFormValues] = useState(initialValues)
  const [formErrors, setFormErrors] = useState({})
  const [isSubmit, setIsSubmit] = useState(false)

  const { register, isPending, error } = useRegister()

  const _registerRef = useRef(register).current
  const _registerCb = useCallback(() => {
    setIsSubmit(false)

    _registerRef(
      formValues.name.trim(),
      formValues.email.trim(),
      formValues.password
    )
  }, [formValues.name, formValues.email, formValues.password, _registerRef])

  // handle form fields changes
  const handleChange = (e) => {
    let { name, value } = e.target
    setFormValues({ ...formValues, [name]: value })
  }

  // validate form
  const validate = (values) => {
    const errors = {}
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i

    if (!values.email.trim()) {
      errors.email = 'Email is required'
    } else if (!regex.test(values.email.trim())) {
      errors.email = 'Email is not valid'
    }

    if (!values.password) {
      errors.password = 'Password is required'
    } else if (values.password.length < 6) {
      errors.password = 'Password must be more than 6 characters'
    } else if (values.password.length > 10) {
      errors.password = 'Password cannot exceed more than 10 characters'
    }

    if (values.confirmPassword !== values.password)
      errors.confirmPassword = 'Passwords not match'

    return errors
  }

  // handle form submission
  const handleSubmit = (e) => {
    e.preventDefault()

    setFormErrors(validate(formValues))
    setIsSubmit(true)
  }

  // handle show/hide password
  const handleShowHidePassword = (e) => {
    const passwordField =
      e.currentTarget.parentElement.parentElement.getElementsByTagName(
        'input'
      )[0]
    const eyeIcon = e.currentTarget
    togglePassword(passwordField, eyeIcon)
  }

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      // register user once the form is valid
      _registerCb()

      // reset form
      setFormValues({ name: '', email: '', password: '', confirmPassword: '' })
    }
  }, [formErrors, isSubmit, _registerCb])

  return (
    <div className="row">
      {/* <pre>formValues: {JSON.stringify(formValues, undefined, 2)}</pre>
      <pre>formErrors: {JSON.stringify(formErrors, undefined, 2)}</pre>
      <pre>isSubmit: {JSON.stringify(isSubmit, undefined, 2)}</pre> */}
      <div className="col-lg-7 col-xl-5 mx-auto">
        <div className="card">
          <div className="card-header">Register</div>
          <div className="card-body">
            <form onSubmit={handleSubmit} noValidate>
              <div className="mb-3">
                <label htmlFor="name" className="form-label">
                  Name
                </label>
                <input
                  type="text"
                  className="form-control form-control-lg"
                  id="name"
                  name="name"
                  value={formValues.name}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="email" className="form-label">
                  Email <span className="required">(required)</span>
                </label>
                <input
                  type="email"
                  className={`form-control form-control-lg ${
                    formErrors.email && 'is-invalid'
                  }`}
                  id="email"
                  name="email"
                  value={formValues.email}
                  onChange={handleChange}
                />
                {formErrors.email && (
                  <div className="invalid-feedback fw-light">
                    {formErrors.email}
                  </div>
                )}
              </div>
              <div className="mb-3">
                <label htmlFor="password" className="form-label">
                  Password <span className="required">(required)</span>
                </label>
                <div className="position-relative">
                  <input
                    type="password"
                    autoComplete="new-password"
                    className={`form-control form-control-lg ${
                      formErrors.password && 'is-invalid'
                    }`}
                    id="password"
                    name="password"
                    value={formValues.password}
                    onChange={handleChange}
                  />
                  <div className="show-password-icon">
                    <i
                      className="bi bi-eye"
                      onClick={handleShowHidePassword}
                    ></i>
                  </div>
                  {formErrors.password && (
                    <div className="invalid-feedback fw-light">
                      {formErrors.password}
                    </div>
                  )}
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="confirmPassword" className="form-label">
                  Confirm Password <span className="required">(required)</span>
                </label>
                <div className="position-relative">
                  <input
                    type="password"
                    autoComplete="new-password"
                    className={`form-control form-control-lg ${
                      formErrors.confirmPassword && 'is-invalid'
                    }`}
                    id="confirmPassword"
                    name="confirmPassword"
                    value={formValues.confirmPassword}
                    onChange={handleChange}
                  />
                  <div className="show-password-icon">
                    <i
                      className="bi bi-eye"
                      onClick={handleShowHidePassword}
                    ></i>
                  </div>
                  {formErrors.confirmPassword && (
                    <div className="invalid-feedback fw-light">
                      {formErrors.confirmPassword}
                    </div>
                  )}
                </div>
              </div>
              {isPending ? (
                <button
                  type="submit"
                  className="btn btn-primary btn-lg mt-2 mb-3 disabled"
                >
                  <span className="spinner-border spinner-form-button"></span>
                  Register
                </button>
              ) : (
                <button
                  type="submit"
                  className="btn btn-outline-primary btn-lg mt-2 mb-3"
                >
                  Register
                </button>
              )}
            </form>
          </div>
        </div>
        <hr className="mt-4" />
        <p className="fw-light ms-1">
          Already have an account?{' '}
          <Link to="/login" className="a-dotted">
            Login
          </Link>
        </p>
      </div>
    </div>
  )
}

export default Register
