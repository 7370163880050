import React, { useState } from 'react'

// components
import TransactionModal from './TransactionModal'

// hooks
import { useFirestore } from '../hooks/useFirestore'

// utils
import { currencyFormatter } from '../utils'

const TransactionList = ({ transactions }) => {
  const { removeDocument } = useFirestore('transactions')
  const [selectedTransaction, setSelectedTransaction] = useState(null)

  const handleRemove = (e) => {
    e.preventDefault()

    removeDocument(selectedTransaction)

    document.querySelector('.btn-close').click()
  }

  if (transactions.length === 0)
    return (
      <p className="lead fw-light mt-5 text-center">
        Sorry, no transactions found!
      </p>
    )

  return (
    <>
      {transactions.map((transaction) => {
        return (
          <div className="card card-transaction py-1 mb-2" key={transaction.id}>
            <span className="card-mark"></span>
            <span
              className="card-action card-close"
              title="remove"
              data-bs-toggle="modal"
              data-bs-target="#removeTrans"
              onClick={() => setSelectedTransaction(transaction.id)}
            ></span>
            {/* <span className="card-action card-edit" title="edit"></span> */}
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-center">
                <h4 className="mb-0 pe-5 fw-light">{transaction.name}</h4>
                <h4 className="mb-0 pe-3 fw-light">
                  {currencyFormatter(Number(transaction.amount))}
                </h4>
              </div>
            </div>
          </div>
        )
      })}
      <TransactionModal
        title="Remove Transaction"
        message="Are you sure you wish to remove this transaction?"
        action={handleRemove}
      />
      {/* transaction modal */}
    </>
  )
}

export default TransactionList
