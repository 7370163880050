import React from 'react'
import Shimmer from './Shimmer'
import SkeletonElement from './SkeletonElement'

const SkeletonItem = () => {
  return (
    <div className={`skeleton-wrapper`}>
      <div className="card py-1 mb-2">
        <div className="card-body">
          <div className="d-flex justify-content-between align-items-center">
            <h4 className="mb-0 pe-3 fw-light">
              <SkeletonElement type="title" />
            </h4>
            <h4 className="mb-0 pe-3 fw-semibold">
              <SkeletonElement type="price" />
            </h4>
          </div>
        </div>
      </div>
      <Shimmer />
    </div>
  )
}

export default SkeletonItem
