import React from 'react'
import ReactDOM from 'react-dom'

// theme (css/js)
import './index.scss'
import 'bootstrap/dist/js/bootstrap.bundle.min.js'

// components
import App from './App'

// context provider
import { AuthContextProvider } from './context/AuthContext'

ReactDOM.render(
  <React.StrictMode>
    <AuthContextProvider>
      <App />
    </AuthContextProvider>
  </React.StrictMode>,
  document.getElementById('root')
)
