import { useEffect, useRef, useState } from 'react'
import { projectFirestore } from '../firebase/config'

// utils
import { errorMsg } from '../alerts'

export const useCollection = (collection, _query, _orderBy) => {
  const [documents, setDocuments] = useState(null)
  const [error, setError] = useState(null)
  const [isPending, setIsPending] = useState(false)
  const [subTotal, setSubTotal] = useState(0)

  // if we don't use a ref -> infinite loop in useEffect
  // _query is an array and is "different" on every function call
  const query = useRef(_query).current
  const orderBy = useRef(_orderBy).current

  useEffect(() => {
    let ref = projectFirestore.collection(collection)

    setIsPending(true)

    // filter by user id
    if (query) ref = ref.where(...query)

    // sort by date
    if (orderBy) ref = ref.orderBy(...orderBy)

    const unsub = ref.onSnapshot(
      (snapshot) => {
        let results = []
        snapshot.docs.forEach((doc) => {
          results.push({ ...doc.data(), id: doc.id })
        })

        // subtotal
        setSubTotal(
          results.reduce((acc, trans) => acc + Number(trans.amount), 0)
        )

        // update state
        setDocuments(results)
        setError(null)
        setIsPending(false)
      },
      (err) => {
        console.log(err)
        setError(errorMsg.noFetchData)
        setIsPending(false)
      }
    )

    // unsubscribe on unmount
    return () => unsub()
  }, [collection, query, orderBy])

  return { documents, error, isPending, subTotal }
}
