import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate
} from 'react-router-dom'
import { ToastContainer, Zoom } from 'react-toastify'

// styles
import 'react-toastify/dist/ReactToastify.css'

// hooks
import { useAuthContext } from './hooks/useAuthContext'

// components
import Navbar from './component/Navbar'
import Disclaimer from './component/Disclaimer'

// pages
import Home from './pages/Home'
import Login from './pages/Login'
import Register from './pages/Register'

const App = () => {
  const { authIsReady, user } = useAuthContext()

  return (
    <div className="App">
      {!authIsReady && (
        <div
          className="d-flex justify-content-center align-items-center text-primary"
          style={{ height: '300px' }}
        >
          <span
            className="spinner-border"
            style={{ width: '3.5rem', height: '3.5rem', borderWidth: '3px' }}
          ></span>
        </div>
      )}
      {authIsReady && (
        <Router>
          <div className="container-vertical">
            <div className="container-vertical-content">
              <Navbar />
              <div className="container">
                <Routes>
                  <Route
                    exact
                    path="/"
                    element={(() =>
                      user ? <Home /> : <Navigate to="/login" />)()}
                  />
                  <Route
                    path="/login"
                    element={(() =>
                      !user ? <Login /> : <Navigate to="/" />)()}
                  />
                  <Route
                    path="/register"
                    element={(() =>
                      !user ? <Register /> : <Navigate to="/" />)()}
                  />
                </Routes>
              </div>
            </div>
          </div>
          <Disclaimer />
          <ToastContainer
            position="top-center"
            autoClose={3000}
            newestOnTop={false}
            closeOnClick
            pauseOnFocusLoss
            draggable
            theme={'colored'}
            transition={Zoom}
            closeButton={true}
            style={{ fontWeight: '300' }}
          />
        </Router>
      )}
    </div>
  )
}

export default App
