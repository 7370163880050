import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

// firebase
import { projectAuth } from '../firebase/config'

// context
import { useAuthContext } from './useAuthContext'

// alert
import { successMsg } from '../alerts'

export const useLogout = () => {
  const navigate = useNavigate()

  const [isCancelled, setIsCancelled] = useState(false)
  const [error, setError] = useState(null)
  const [isPending, setIsPending] = useState(false)
  const { dispatch } = useAuthContext()

  const logout = async () => {
    setError(null)
    setIsPending(true)

    // sign the user out
    try {
      await projectAuth.signOut()

      // dispatch logout action
      dispatch({ type: 'LOGOUT' })

      // update state
      if (!isCancelled) {
        setIsPending(false)
        setError(null)

        // alert
        toast.dismiss()
        toast.success(successMsg.loggedOut.replace('Firebase: ', ''))

        // navigate to home page
        navigate('/')
      }
    } catch (err) {
      if (!isCancelled) {
        // console.log(err.message)

        // update state
        setError(err.message)
        setIsPending(false)

        // alert
        toast.dismiss()
        toast.error(err.message.replace('Firebase: ', ''), { autoClose: 20000 })
      }
    }
  }

  useEffect(() => {
    return () => setIsCancelled(true)
  }, [])

  return { logout, error, isPending }
}
