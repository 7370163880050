import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'

// utils
import { togglePassword } from '../utils'

// hooks
import { useLogin } from '../hooks/useLogin'

const Login = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [isSubmit, setIsSubmit] = useState(false)

  const { login, isPending, error } = useLogin()
  const _loginRef = useRef(login).current

  const _loginCb = useCallback(() => {
    setIsSubmit(false)

    _loginRef(email.trim(), password)
  }, [email, password, _loginRef])

  const handleSubmit = (e) => {
    e.preventDefault()

    setIsSubmit(true)
  }

  // handle show/hide password
  const handleShowHidePassword = (e) => {
    const passwordField =
      e.currentTarget.parentElement.parentElement.getElementsByTagName(
        'input'
      )[0]
    const eyeIcon = e.currentTarget
    togglePassword(passwordField, eyeIcon)
  }

  useEffect(() => {
    if (isSubmit) {
      _loginCb()

      // reset form
      // setEmail('')
      // setPassword('')
    }
  }, [isSubmit, _loginCb])

  return (
    <div className="row">
      <div className="col-lg-7 col-xl-5 mx-auto">
        <div className="card">
          <div className="card-header">Login</div>
          <div className="card-body">
            <form onSubmit={handleSubmit} noValidate>
              <div className="mb-3">
                <label htmlFor="email" className="form-label">
                  Email
                </label>
                <input
                  type="email"
                  className="form-control form-control-lg"
                  id="email"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="password" className="form-label">
                  Password
                </label>
                <div className="position-relative">
                  <input
                    type="password"
                    autoComplete="new-password"
                    className="form-control form-control-lg"
                    id="password"
                    name="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <div className="show-password-icon">
                    <i
                      className="bi bi-eye"
                      onClick={handleShowHidePassword}
                    ></i>
                  </div>
                </div>
              </div>
              <div className="mb-3">
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="rememberMe"
                  />
                  <label
                    className="form-check-label position-relative"
                    htmlFor="rememberMe"
                    style={{ top: '1px' }}
                  >
                    Remember me
                  </label>
                </div>
              </div>
              {isPending ? (
                <button
                  type="submit"
                  className="btn btn-primary btn-lg mt-2 mb-3 disabled"
                >
                  <span className="spinner-border spinner-form-button"></span>
                  Login
                </button>
              ) : (
                <button
                  type="submit"
                  className="btn btn-outline-primary btn-lg mt-2 mb-3"
                >
                  Login
                </button>
              )}
            </form>
          </div>
        </div>
        <hr className="mt-4" />
        <p className="fw-light ms-1">
          Need an account?{' '}
          <Link to="/register" className="a-dotted">
            Register
          </Link>
        </p>
      </div>
    </div>
  )
}

export default Login
