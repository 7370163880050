import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { projectAuth } from '../firebase/config'
import { toast } from 'react-toastify'

// context
import { useAuthContext } from './useAuthContext'

// alert
import { errorMsg, successMsg } from '../alerts'

export const useRegister = () => {
  const navigate = useNavigate()

  const [isCancelled, setIsCancelled] = useState(false)
  const [error, setError] = useState(null)
  const [isPending, setIsPending] = useState(false)
  const { dispatch } = useAuthContext()

  const register = async (name, email, password) => {
    setError(null)
    setIsPending(true)

    try {
      // register user
      const res = await projectAuth.createUserWithEmailAndPassword(
        email,
        password
      )

      if (!res) throw new Error(errorMsg.registered)

      // add name to user
      await res.user.updateProfile({ displayName: name })

      // dispatch login action
      dispatch({ type: 'LOGIN', payload: res.user })

      // update state
      if (!isCancelled) {
        // console.log(res.user)

        setIsPending(false)
        setError(null)

        // alert
        toast.dismiss()
        toast.success(successMsg.registered.replace('Firebase: ', ''))

        // navigate to login page
        navigate('/')
      }
    } catch (err) {
      if (!isCancelled) {
        // console.log(err.message)

        // update state
        setIsPending(false)
        setError(err.message)

        // alert
        toast.dismiss()
        toast.error(err.message.replace('Firebase: ', ''), { autoClose: 20000 })
      }
    }
  }

  useEffect(() => {
    return () => setIsCancelled(true)
  }, [])

  return { register, error, isPending }
}
